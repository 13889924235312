import { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { LocaleContext, ExperimentContext } from "contexts"

const query = graphql`
  query useTranslations {
    rawData: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            helpdeskLink
            appLink
            seoTitleApp
            basePrice
            partnerPrice
            currency
            normalPrice
            yearShort
            monthShort
            personShortSingle
            personShortPlural
            blogTitle
            coverTitle
            coverSubTitle1
            coverSubTitle2
            buttonBuy
            buttonLogin
            buttonLoginHelp
            buttonOpenVideo
            buttonMore
            statsSectionTitle
            statsSectionDescription
            statsSectionData1
            statsSectionData2
            statsSectionData3
            statsSectionMainText
            statsSectionLink
            helpSectionStats
            helpSectionStatsSource
            helpSectionLockStats
            helpSectionLockNumber
            helpSectionListTitle
            helpSectionListText1
            helpSectionListText2
            helpSectionListText3
            helpSectionListText4
            helpSectionListText5
            helpSectionListText6
            helpSectionListText7
            helpSectionButton
            platformsSectionTitle
            platformsSectionText1
            platformsSectionText2
            platformsSectionText3
            platformsSectionText4
            platformsIconPlus
            platformsGuideLink
            orderSectionTitle
            orderSectionSubtitle
            orderSectionNormalPrice
            orderSectionPriceComment
            orderSectionPriceAsteriskText
            orderSectionButton
            orderSectionListTitle
            orderSectionListElement1
            orderSectionListElement2
            orderSectionListElement3
            orderSectionListElementLink
            orderSectionListElement4
            orderSectionListElement5
            orderSectionListElement6
            orderModalTitle
            orderModalPriceComment
            orderModalDescription
            orderModalProductName
            orderModalPriceTotal
            orderModalTerms1
            orderModalTerms2
            orderModalTerms3
            orderModalTerms4
            orderModalButton
            orderModalTermsError1
            orderModalTermsError2
            orderModalTermsError3
            orderModalTermsError4
            orderModalTermsError5
            orderModalInvoiceRequest1
            orderModalInvoiceRequestLink
            orderModalInvoiceRequest2
            orderModalInvoiceRequest3
            mediaSectionTitle
            mediaSectionButton
            menuLinkHome
            menuLinkApp
            menuLinkAboutUs
            menuLinkMedia
            menuLinkBlog
            menuLinkWebinar
            menuLinkCourses
            menuLinkHelpdesk
            menuLinkPricing
            menuLinkCountry
            menuLinkParents
            menuLinkSomeUser
            menuLinkInfluencers
            menuLinkMunicipal
            menuLinkPrivacyPolicy
            menuLinkTermsOfService
            menuLinkWebshopOrderTerms
            menuLinkWebshopPrivacyTerms
            menuLinkLegal
            menuLinkTurvaopasTiktok
            menuLinkCookiePrefs
            menuLinkSchoolMain
            menuLinkSchoolTrainings
            menuLinkSchoolLessons
            menuLinkCompaniesMain
            menuLinkCompaniesTrainings
            menuLinkCompaniesOnboarding
            menuLinkCompaniesMainFull
            menuLinkCompaniesTrainingsFull
            menuLinkSchoolsOnboarding
            menuLinkParentsLessons
            page404Title
            page404Description
            pageMediaTitle
            pageOrderCompletedButton
            pageOrderCompletedTitle
            pageOrderCompletedHeader
            pageOrderCompletedPartner
            pageOrderCompletedSubHeader
            pageOrderCompletedDescription
            pageAppHeader
            pageAppSubheader
            pageAppCodeNegativeHeader
            pageAppCodeNegativeText
            pageAppCodeNegativeButton
            pageAppSometurvaHeading
            pageAppSometurvaDescription
            pageAppProHeading
            pageAppProDescription
            pageAppPricingLink
            functionalCookieBannerText
            functionalCookieBannerExplainer
            functionalCookieBannerButtonAccept
            functionalCookieBannerButtonReject
            cookieBannerTitle
            cookieBannerText1
            cookieBannerText2
            cookieBannerButtonMore
            cookieBannerButtonAcceptAll
            cookieBannerButtonRejectAll
            cookieBannerEssentialTitle
            cookieBannerEssentialDescription
            cookieBannerEssentialAlwaysActive
            cookieBannerFunctionalTitle
            cookieBannerFunctionalDescription
            cookieBannerAnalyticsTitle
            cookieBannerAnalyticsDescription
            cookieBannerButtonLess
            cookieBannerButtonAccept
            cookieBannerButtonReject
            cookieBannerButtonSave
            cookieBannerLinkText
            pdfPrivacyPolicyLink
            pdfShopTermsLink
            cookiePolicyLink
            privacyPolicyLink
            blogOrderSectionHeader
            blogOrderSectionSubheader
            blogOrderSectionButton
            blogNoPosts
            blogAllPosts
            teamText1
            teamText2
            teamText3
            teamText4
            teamText5
            teamText6
            teamCallout1
            teamHeading1
            teamHeading2
            teamHeading3
            teamHeading4
            teamLocation1
            teamLocation2
            teamRecruitName
            teamRecruitPosition
            teamRecruitLinkHref
            teamRecruitLinkText
            parentalTitle1
            parentalTitle2
            parentalTitle3
            parentalDescription
            parentalHeroButton
            parentalFeaturesTitle1
            parentalFeatures1_1
            parentalFeatures1_2
            parentalFeatures1_3
            parentalFeaturesTitle2
            parentalFeatures2_1
            parentalFeatures2_2
            parentalFeatures2_3
            parentalFeedbackTitle
            parentalFeedbackSubitle
            parentalFeedback1_Review
            parentalFeedback1_Author
            parentalFeedback1_AboutAuthor
            parentalFeedback2_Review
            parentalFeedback2_Author
            parentalFeedback2_AboutAuthor
            parentalFeedback3_Review
            parentalFeedback3_Author
            parentalFeedback3_AboutAuthor
            parentalOrderTitle
            parentalOrderPriceComment
            parentalOrderBestFeature1
            parentalOrderBestFeature2
            parentalOrderBestFeature3
            parentalMediaTitle
            parentalMediaQuote1
            parentalMediaQuote2
            parentalMediaQuote3
            subscribeHeader
            subscribeHeaderSchool
            subscribeButton
            subscribeEmailPlaceholder
            subscribePrivacyPolicy1
            subscribePrivacyPolicy2
            ourCustomers
            faqTitle
            faqSubtitle1
            faqSubtitleLink
            faqSubtitle2
            bannerShort
            bannerLong
            bannerButton
            bannerLink
            openWebappSmall
            openWebappLarge
            howItWorksTitle
            howItWorksFeature1Title
            howItWorksFeature1_1
            howItWorksFeature1_2
            howItWorksFeature1_3
            howItWorksFeature1_4
            howItWorksFeature2Title
            howItWorksFeature2_1
            howItWorksFeature2_2
            howItWorksFeature3Title
            howItWorksFeature3_1
            howItWorksFeature4Title
            howItWorksFeature4_1
            howItWorksFeature4_2
            howItWorks2Feature1Title
            howItWorks2Feature1Text
            howItWorks2Feature2Title
            howItWorks2Feature2Text
            howItWorks2Feature3Title
            howItWorks2Feature3Text
            influencerTitle1
            influencerTitle2
            influencerTitle3
            influencerDescription
            influencerHeroButton1
            influencerHeroButton2
            influencerFeedbackTitle
            influencerFeedbackSubitle
            influencerFeedback1_Title
            influencerFeedback1_Review
            influencerFeedback1_Author
            influencerFeedback1_AboutAuthor
            influencerFeedback2_Title
            influencerFeedback2_Review
            influencerFeedback2_Author
            influencerFeedback2_AboutAuthor
            influencerFeedback3_Title
            influencerFeedback3_Review
            influencerFeedback3_Author
            influencerFeedback3_AboutAuthor
            influencerFeaturesTitle1
            influencerFeatures1_1
            influencerFeatures1_2
            influencerFeatures1_3
            influencerFeatures1_4
            influencerHowDoWeHelpTitle
            influencerHowDoWeHelp1Title
            influencerHowDoWeHelp1Text
            influencerHowDoWeHelp2Title
            influencerHowDoWeHelp2Text
            influencerHowDoWeHelp3Title
            influencerHowDoWeHelp3Text
            influencerPricingTitle
            influencerPeopleTitle
            influencerPeopleText1
            influencerPeopleText2
            generalInfluencerTitle2
            generalInfluencerFeaturesTitle1
            generalInfluencerFeatures1_3
            generalInfluencerHowDoWeHelp2Text
            generalInfluencerPeopleText2
            generalInfluencersMediaTitle
            generalInfluencersMediaQuote1
            generalInfluencersMediaQuote2
            generalInfluencersMediaQuote3
            generalInfluencersMediaQuote4
            generalInfluencersMediaQuote1Link
            generalInfluencersMediaQuote2Link
            generalInfluencersMediaQuote3Link
            generalInfluencersMediaQuote4Link
            generalInfluencersPlanTitle
            generalInfluencersPlanText1
            generalInfluencersPlanText2
            generalInfluencersPlanLink
            mainRequestDemoButton
            hrTitle1
            hrTitle2
            hrTitle3
            hrHighlight
            hrDescription
            hrHeroButton1
            hrHeroButtonComment
            hrHeroButton2
            hrFeaturesTitle1
            hrFeatures1_1
            hrFeatures1_2
            hrFeatures1_3
            hrFeatures1_4
            hrFeatures1_5
            hrHowDoWeHelpTitle
            hrHowDoWeHelp1Title
            hrHowDoWeHelp1Text
            hrHowDoWeHelp2Title
            hrHowDoWeHelp2Text
            hrHowDoWeHelp3Title
            hrHowDoWeHelp3Text
            hrHowDoWeHelp4Title
            hrHowDoWeHelp4Text
            hrHowDoWeHelpButton
            hrHowDoWeHelpButtonComment
            hrWhistleblowingTitle
            hrWhistleblowingSubtitle
            hrWhistleblowingText1
            hrWhistleblowingText2
            hrWhistleblowingButton
            hrWhistleblowingButtonComment
            hrCaseQuoteTitle
            hrCaseQuote1Title
            hrCaseQuote1Text
            hrCaseQuote2Title
            hrCaseQuote2Text
            hrCaseQuote3Title
            hrCaseQuote3Text
            hrFeedbackTitle
            hrFeedbackSubitle
            hrFeedback1_Title
            hrFeedback1_Review
            hrFeedback1_Author
            hrFeedback1_AboutAuthor
            hrFeedback2_Title
            hrFeedback2_Review
            hrFeedback2_Author
            hrFeedback2_AboutAuthor
            hrFeedback3_Review
            hrFeedback3_Author
            hrFeedback3_AboutAuthor
            hrRequestDemoTitle
            hrDemoFeature1
            hrDemoFeature2
            hrDemoFeature3
            pricingTierInfluencerOneCaseName
            pricingTierInfluencerOneCasePrice
            pricingTierInfluencerOneCasePricePartner
            pricingTierInfluencerOneCaseDuration
            pricingTierInfluencerOneCaseDescription
            pricingTierInfluencerSubscriptionName
            pricingTierInfluencerSubscriptionPrice
            pricingTierInfluencerSubscriptionPricePartner
            pricingTierInfluencerSubscriptionDuration
            pricingTierInfluencerSubscriptionDescription
            pricingTierBaseOneCaseName
            pricingTierBaseOneCasePrice
            pricingTierBaseOneCaseDuration
            pricingTierBaseOneCaseDescription
            pricingTierBaseSubscriptionName
            pricingTierBaseSubscriptionPrice
            pricingTierBaseSubscriptionDuration
            pricingTierBaseSubscriptionDescription
            pricingTierParentalOneCaseName
            pricingTierParentalOneCasePrice
            pricingTierParentalOneCaseDuration
            pricingTierParentalOneCaseDescription
            pricingTierParentalSubscriptionName
            pricingTierParentalSubscriptionPrice
            pricingTierParentalSubscriptionDuration
            pricingTierParentalSubscriptionDescription
            tierFeature1
            tierFeature2
            tierFeature3
            tierFeature4
            tierFeature5
            vatIncluded
            caseQuoteTitle
            caseQuote1Title
            caseQuote1Text
            caseQuote2Title
            caseQuote2Text
            caseQuote3Title
            caseQuote3Text
            footerContact
            footerContactButton
            dashboard_socialmediaChannels1
            dashboard_socialmediaChannels2
            dashboard_descending
            dashboard_histogramSorted
            dashboard_crimeCategories
            dashboard_defamation
            dashboard_harassingCommunication
            dashboard_violatingPersonalPrivacy1
            dashboard_violatingPersonalPrivacy2
            dashboard_stalking
            dashboard_menace
            dashboard_illicitObservation1
            dashboard_illicitObservation2
            dashboard_identityTheft1
            dashboard_identityTheft2
            dashboard_other
            dashboard_criticalCases
            dashboard_criticalCase1
            dashboard_criticalCase2
            dashboard_criticalCase3
            dashboard_positive
            dashboard_negative
            dashboard_trusted
            dashboard_valued
            dashboard_good
            dashboard_understanding
            dashboard_down
            dashboard_anxious
            dashboard_stressed
            dashboard_howYourEmployeesAreFeeling
            dashboard_onlineWellbeingPulse1
            dashboard_onlineWellbeingPulse2
            dashboard_notACrime
            dashboard_crime
            dashboard_ofCases
            dashboard_crimeOrNot
            dashboard_reportedCases
            dashboard_cases
            dashboard_protectedEmployees
            dashboard_people
            dashboard_statisticsForLast7Days
            webinarTitle
            webinarRequestText1
            webinarRequestText2
            webinarRequestLinkText
            webinarEmptyText
            webinarSubscribeButton
            blogTimeToRead
            blogMinute
            blogMinutes
            segmentCardParentsName
            segmentCardParentsDescription
            segmentCardParentsButton
            segmentCardInfluencersName
            segmentCardInfluencersDescription
            segmentCardInfluencersButton
            segmentCardSocialMediaUsersName
            segmentCardSocialMediaUsersDescription
            segmentCardSocialMediaUsersButton
            segmentCardMunicipalName
            segmentCardMunicipalDescription
            segmentCardMunicipalButton
            segmentCardPriceFrom
            allSomeGuidesTitle
            allSomeGuidesSubtitle
            municipalPageTitle
            municipalPageDescription
            municipalTitle1
            municipalTitle2
            municipalTitle3
            municipalDescription
            municipalHeroButton
            municipalHeroButtonComment
            municipalHeroLanguages
            municipalQuote1Title
            municipalQuote1Text
            municipalQuote1Name
            municipalQuote2Title
            municipalQuote2Text
            municipalQuote2Name
            municipalQuote3Title
            municipalQuote3Text
            municipalQuote3Name
            municipalQuestion1Title
            municipalQuestion1Text1
            municipalQuestion1Text2
            municipalQuestion1Text3
            municipalQuestion2Title
            municipalQuestion2Text
            municipalActivationTitle
            municipalActivationSubtitle
            municipalActivation1_1
            municipalActivation1_2
            municipalActivation2_1
            municipalActivation2_2
            municipalActivation2_3
            municipalActivation3_1
            municipalActivation3_2
            municipalActivation3_3
            municipalActivation4
            municipalProgramTitle
            municipalProgramText
            municipalProgramLink
            municipalOver24Text
            videoPageTitle
            otherSectionVideo1Title
            otherSectionVideo1Description
            videoSection1Video1Title
            otherSectionVideo1Link
            otherSectionVideo2Title
            otherSectionVideo2Description
            videoSection2Video1Title
            otherSectionVideo2Link
            videoSection3Title
            videoSection3Description
            videoSection3Video1Title
            videoSection3Video1Link
            videoSection3Video2Title
            videoSection3Video2Link
            webinarRecordingTitle
            webinarRecordingText1
            webinarRecordingText2
            webinarRecordingLink
            proHowDoWeHelpTitle
            proStepsItem1
            proStepsItem2
            proStepsItem3
            schoolHeroTitle
            schoolHeroHighlight
            schoolHeroDescription1
            schoolHeroDescription2
            schoolHowDoWeHelpTitle
            schoolHelpListItem1
            schoolHelpListItem2
            schoolHelpListItem3
            schoolCaseQuoteTitle
            schoolCaseQuote1Title
            schoolCaseQuote1Text
            schoolCaseQuote2Title
            schoolCaseQuote2Text
            schoolCaseQuote3Title
            schoolCaseQuote3Text
            schoolFeedbackTitle
            schoolFeedback1_Review
            schoolFeedback1_Author
            schoolFeedback1_AboutAuthor
            schoolFeedback2_Review
            schoolFeedback2_Author
            schoolFeedback2_AboutAuthor
            schoolFeedback3_Review
            schoolFeedback3_Author
            schoolFeedback3_AboutAuthor
            schoolDemoTitle
            schoolDemoFeature1
            schoolDemoFeature2
            schoolDemoFeature3
            schoolCommunitiesTitle
            schoolCommunitiesStudentsLabel
            schoolCommunitiesStudents1
            schoolCommunitiesStudents2
            schoolCommunitiesStudents3
            schoolCommunitiesTeachersLabel
            schoolCommunitiesTeachers1
            schoolCommunitiesTeachers2
            schoolCommunitiesTeachers3
            schoolCommunitiesManagerLabel
            schoolCommunitiesManager1
            schoolCommunitiesManager2
            schoolCommunitiesManager3
            schoolWebinarTitle
            schoolWebinarRequestText1
            schoolWebinarRequestText2
            schoolWebinarRequestLinkText
            schoolWebinarEmptyText
            schoolFactGirls1
            schoolFactGirls2
            schoolFactGirls3
            schoolFactGirlsPercentage
            schoolFactStudents1
            schoolFactStudents2
            schoolFactStudents3
            schoolFactStudentsPercentage
            schoolsInfo1Title
            schoolsInfo1Text
            schoolsInfo1Highlight
            schoolsInfo1SmallText
            schoolsInfo2Title
            schoolsInfo2Text
            schoolsInfo2Highlight
            schoolsInfo2SmallText
            schoolsPdfTitle
            schoolsPdfSubtitle
            schoolsPdfFormJobOptions
            schoolsPdfFormButton
            schoolsPdfErrorGeneral
            schoolsPdfErrorUserExist
            schoolsPdfErrorContactUs
            schoolsPdfSuccess
            navMenuPersonal
            navMenuSchools
            navMenuCompanies
            navMenuOther
            navMenuOtherCompany
            navMenuOtherResources
            navMenuBlog
            navMenuBlogAll
            navMenuProductCardMore
            legalLastUpdated
            legalPageName
            proStepsItem1Title
            proStepsItem2Title
            proStepsItem3Title
            blogSectionTitle
            testimonial1Id
            testimonial1Title
            testimonial1Description
            testimonial1PersonName
            testimonial1PersonDescription
            testimonial1FactLarge
            testimonial1FactSmall
            testimonial12Id
            testimonial2Title
            testimonial2Description
            testimonial2PersonName
            testimonial2PersonDescription
            testimonial2FactLarge
            testimonial2FactSmall
            testimonialEn1Id
            testimonialEn1Title
            testimonialEn1Description
            testimonialEn1PersonName
            testimonialEn1PersonDescription
            testimonialEn1FactLarge
            testimonialEn1FactSmall
            testimonialEn12Id
            testimonialEn2Title
            testimonialEn2Description
            testimonialEn2PersonName
            testimonialEn2PersonDescription
            testimonialEn2FactLarge
            testimonialEn2FactSmall
            testimonialReadMore
            protectionTitle
            protectionPreventShort
            protectionPreventLong
            protectionPreventContactName
            protectionPreventContactPosition
            protectionPreventContactEmail
            protectionProtectShort
            protectionProtectLong
            protectionProtectContactName
            protectionProtectContactPosition
            protectionProtectContactEmail
            protectionImpactShort
            protectionImpactLong
            protectionImpactContactName
            protectionImpactContactPosition
            protectionImpactContactEmail
            protectionPreventHighlight
            protectionProtectHighlight
            protectionImpactHighlight
            protectionReadMore
            problemsProTitle
            problemsPro1Title
            problemsPro1Description
            problemsPro2Title
            problemsPro2Description
            problemsPro3Title
            problemsPro3Description
            problemsPro4Title
            problemsPro4Description
            problemsPro5Title
            problemsPro5Description
            problemsMainTitle
            problemsMain1Title
            problemsMain1Description
            problemsMain2Title
            problemsMain2Description
            problemsMain3Title
            problemsMain3Description
            problemsMain4Title
            problemsMain4Description
            problemsMain5Title
            problemsMain5Description
            impactTitle
            impact1Title
            impact1Description
            impact2Title
            impact2Description
            impact3Title
            impact3Description
            impact4Title
            impact4Description
            impact5Title
            impact5Description
            impact6Title
            impact6Description
            serviceProTitle
            servicePro1Heading
            servicePro1Subheading
            servicePro1Description
            servicePro2Heading
            servicePro2Subheading
            servicePro2Description
            servicePro3Heading
            servicePro3Subheading
            servicePro3Description
            servicePro4Heading
            servicePro4Subheading
            servicePro4Description
            serviceEnTitle
            serviceEn1Heading
            serviceEn1Subheading
            serviceEn1Description
            serviceEn2Heading
            serviceEn2Subheading
            serviceEn2Description
            serviceEn3Heading
            serviceEn3Subheading
            serviceEn3Description
            serviceEn4Heading
            serviceEn4Subheading
            serviceEn4Description
            mainHero1
            mainHero2
            mainHero3
            mainHeroDescription
            mainHeroDescriptionHighlight
            solutionsTitle
            solutions1Name
            solutions1Description
            solutions2Name
            solutions2Description
            solutions3Name
            solutions3Description
            solutionsReadMore
            segmentsHeading
            segmentsSubeading
            segmentsWorkHeading
            segmentsWorkSubheading
            segmentsWorkDescription
            segmentsWorkPrelink
            segmentsWorkLink
            segmentsWorkLinkText
            segmentsSchoolsHeading
            segmentsSchoolsSubheading
            segmentsSchoolsDescription
            segmentsSchoolsPrelink
            segmentsSchoolsLink1
            segmentsSchoolsLink1Text
            segmentsSchoolsLink2
            segmentsSchoolsLink2Text
            segmentsPersonalHeading
            segmentsPersonalSubheading
            segmentsPersonalDescription
            segmentsPersonalPrelink
            segmentsPersonalLink1
            segmentsPersonalLink2
            segmentsProHeading
            segmentsProSubeading
            segmentsProWorkHeading
            segmentsProWorkSubheading
            segmentsProWorkDescription
            segmentsProWorkPrelink
            segmentsProWorkLink
            segmentsProWorkLinkText
            segmentsProSchoolsHeading
            segmentsProSchoolsSubheading
            segmentsProSchoolsDescription
            segmentsProSchoolsPrelink
            segmentsProSchoolsLink1
            segmentsProSchoolsLink1Text
            segmentsProSchoolsLink2
            segmentsProSchoolsLink2Text
            segmentsProPersonalHeading
            segmentsProPersonalSubheading
            segmentsProPersonalDescription
            segmentsProPersonalPrelink
            segmentsProPersonalLink1
            segmentsProPersonalLink2
            companyMissionHeading
            companyMissionDescription
            companyMissionLarge
            companyExpertiseHeading
            companyExpertiseDescription
            companyExpertiseLink1
            companyExpertiseLink2
            companyAiHeading
            companyAiDescription
            companyFact1Large
            companyFact1Small
            companyFact2Large
            companyFact2Small
            companyFact3Large
            companyFact3Small
            companyFact4Large
            companyFact4Small
            companyFact5Large
            companyFact5Small
            companyFact6Large
            companyFact6Small
            contactFormHeading
            contactFormCountry
            contactFormOffice
            contactFormChat
            contactFormName
            contactFormEmail
            contactFormPhone
            contactFormMessage
            contactFormButtonSubmit
            contactFormSuccess
            contactFormError
            contactFormErrorContact

            protectionEnPreventShort
            protectionEnPreventLong
            protectionEnPreventContactName
            protectionEnPreventContactPosition
            protectionEnPreventContactEmail
            protectionEnProtectShort
            protectionEnProtectLong
            protectionEnProtectContactName
            protectionEnProtectContactPosition
            protectionEnProtectContactEmail
            protectionEnImpactShort
            protectionEnImpactLong
            protectionEnImpactContactName
            protectionEnImpactContactPosition
            protectionEnImpactContactEmail
            protectionEnPreventHighlight
            protectionEnProtectHighlight
            protectionEnImpactHighlight
            protectionEnTitle
            mediaSectionEnButton
            mediaSectionEnTitle
            contactFormEnHeading
            contactFormEnOffice
            contactFormEnCountry
            contactFormEnName
            contactFormEnEmail
            contactFormEnPhone
            contactFormEnMessage
            contactFormEnButtonSubmit
            contactFormEnSuccess
            contactFormEnError
            contactFormEnErrorContact
            proWebinarTitle
            sharePage
            checkPage
            proWebinarRecordingHeading
            proWebinarRecordingSubHeading
            proWebinarRecordingDescription
            proWebinarRecordingUrl
            formFieldName
            formFieldEmail
            formFieldJobTitle
            formFieldCity
            formFieldSchool
            formFieldSchoolLevel
            formFieldDate
            formFieldMessage
            formFieldOrganization
            formFieldQuantity
            formFieldOnlineAttendance
            formFieldPhone
            formFieldCertificate
            formFieldLunch
            formFieldLunchDescription
            formButtonSubmit
            formErrorGeneral
            formErrorUserExist
            formErrorContactUs
            formSuccess
            formMoreInfo
            formAdditionalValue
            formRequired
            proWebinarFormJobOptions
            proWebinarFormButton
            proWebinarFormErrorUserExist
            schoolsTrainingQuestionsTitle
            schoolsTrainingQuestionsText1
            schoolsTrainingQuestionsText2
            schoolsTrainingQuestionsText3
            schoolsTrainingQuestionsText4
            schoolsTrainingQuestionsText5
            schoolsTrainingQuestionsText6
            schoolsTrainingOverviewText1
            schoolsTrainingOverviewText2
            schoolsTrainingOverviewLinkText
            schoolsTrainingOverviewText3
            schoolsTrainingHeroTitle
            schoolsTrainingHeroDescription
            webinarHeroButton
            schoolsTrainingHeroList
            schoolsTrainingListShowAll
            schoolsTrainingListShowLess
            schoolsTrainingNewsletter
            webinarPrivacyPolicy
            webinarFormSuccessTitle
            webinarFormSuccessBody
            webinarFormSuccessButton
            trainingFormSuccessBody
            trainingFormSpam
            trainingLiveLink1
            trainingLiveLink2
            trainingSuccess1
            trainingSuccess2
            trainingSuccess3
            addToCalendarTitle
            addToCalendarGoogle
            addToCalendarOutlook
            addToCalendarICS
            materialsPdfLinkText
            otherSectionMaterial1PdfLinkText
            materials1Title
            materials2Title
            materials3Title
            materials4Title
            materials1Lecture1Title
            materials1Lecture1Description
            materials1Lecture1Video
            materials1Lecture1Pdf
            materials1Lecture2Title
            materials1Lecture2Description
            materials1Lecture2Video
            materials1Lecture2Pdf
            materials1Lecture3Title
            materials1Lecture3Description
            materials1Lecture3Video
            materials1Lecture3Pdf
            materials1Lecture4Title
            materials1Lecture4Description
            materials1Lecture4Video
            materials1Lecture4Pdf
            materials1Lecture5Title
            materials1Lecture5Description
            materials1Lecture5Video
            materials1Lecture5Pdf
            materials1Lecture6Title
            materials1Lecture6Description
            materials1Lecture6Video
            materials1Lecture6Pdf
            materials2Lecture1Title
            materials2Lecture1Description
            materials2Lecture1Video
            materials2Lecture1Pdf
            materials2Lecture2Title
            materials2Lecture2Description
            materials2Lecture2Video
            materials2Lecture2Pdf
            materials2Lecture3Title
            materials2Lecture3Description
            materials2Lecture3Video
            materials2Lecture3Pdf
            materials2Lecture4Title
            materials2Lecture4Description
            materials2Lecture4Video
            materials2Lecture4Pdf
            materials2Lecture5Title
            materials2Lecture5Description
            materials2Lecture5Video
            materials2Lecture5Pdf
            materials2Lecture6Title
            materials2Lecture6Description
            materials2Lecture6Video
            materials2Lecture6Pdf
            materials2Lecture7Title
            materials2Lecture7Description
            materials2Lecture7Video
            materials2Lecture7Pdf
            materials3Lecture1Title
            materials3Lecture1Description
            materials3Lecture1Video
            materials3Lecture1Pdf
            materials3Lecture2Title
            materials3Lecture2Description
            materials3Lecture2Video
            materials3Lecture2Pdf
            materials3Lecture3Title
            materials3Lecture3Description
            materials3Lecture3Video
            materials3Lecture3Pdf
            materials3Lecture4Title
            materials3Lecture4Description
            materials3Lecture4Video
            materials3Lecture4Pdf
            materials3Lecture5Title
            materials3Lecture5Description
            materials3Lecture5Video
            materials3Lecture5Pdf
            materials3Lecture6Title
            materials3Lecture6Description
            materials3Lecture6Video
            materials3Lecture6Pdf
            materials3Lecture7Title
            materials3Lecture7Description
            materials3Lecture7Video
            materials3Lecture7Pdf
            materials4Lecture1Title
            materials4Lecture1Description
            materials4Lecture1Video
            materials4Lecture1Pdf
            materials4Lecture2Title
            materials4Lecture2Description
            materials4Lecture2Video
            materials4Lecture2Pdf
            materials4Lecture3Pdf
            materials4Lecture4Title
            materials4Lecture4Description
            materials4Lecture4Pdf
            materials4Lecture4Video
            materials4Lecture5Title
            materials4Lecture5Description
            materials4Lecture5Video
            materials4Lecture5Pdf
            materials4Lecture6Title
            materials4Lecture6Description
            materials4Lecture6Video
            materials4Lecture6Pdf
            materialsOtherTitle
            otherSectionMaterial1Pdf
            otherSectionMaterial1Title
            otherSectionMaterial1Description
            materialsTitle
            materialsDescription
            materialsIntro1
            materialsIntro2
            materialsIntro3
            materialsIntro4
            materialsIntroLink
            materialsCuricculumTitle
            materialsCuricculum1
            materialsCuricculum2
            materialsCuricculum3
            materialsCuricculum4
            materialsCuricculum5
            materialsCuricculum6
            materialsCuricculum7
            materialsCuricculum8
            materialsCuricculum9
            materialsCTA1Title
            materialsCTA1Description
            publishSoon
            proCoursesHeroTitle1
            proCoursesHeroDescription
            proCoachTitle
            proCoach1Name
            proCoach1Position
            proCoach1Title
            proCoach2Name
            proCoach2Position
            proCoach2Title
            proCoach3Name
            proCoach3Position
            proCoach3Title
            proCoursesInfo1Title
            proCoursesInfo1Highlights
            proCoursesInfo1Description1
            proCoursesInfo1Description2
            proCoursesProblemsTitle
            proCoursesProblemsList
            proCoursesSolutionsHeading
            proCoursesSolutionsSubheading
            proCoursesSolutionsIncluded
            proCoursesSolutions1Segment
            proCoursesSolutions1Name
            proCoursesSolutions1Features
            proCoursesSolutions1Effects
            proCoursesSolutions2Segment
            proCoursesSolutions2Name
            proCoursesSolutions2Features
            proCoursesSolutions2Effects
            proCoursesSolutions3Segment
            proCoursesSolutions3Name
            proCoursesSolutions3Features
            proCoursesSolutions3Effects
            proCoursesSolutionsPoint1Title
            proCoursesSolutionsPoint1Description
            proCoursesSolutionsPoint2Title
            proCoursesSolutionsPoint2Description
            proCoursesSolutionsPoint3Title
            proCoursesSolutionsPoint3Description
            quotesTitle
            quoteMore
            newsletterFormSuccessTitle
            newsletterFormSuccessBody
            onboardingHeroTitle
            onboardingHeroDescription
            onboardingVideoTItle
            onboardingVideoDescription1
            onboardingVideoDescription2
            onboardingVideoDescription3
            onboardingIntroTitle
            onboardingIntroDescription1
            onboardingIntroDescription2
            onboardingSection1Title
            onboardingSection2Title
            onboardingSection3Title
            onboardingSection4Title
            onboardingSection5Title
            onboardingFeature1Title
            onboardingFeature1Description
            onboardingFeature2Title
            onboardingFeature2Description
            onboardingFeature3Title
            onboardingFeature3Description
            onboardingSituationsDescription1
            onboardingSituationsList1
            onboardingSituationsList2
            onboardingSituationsList3
            onboardingSituationsList4
            onboardingSituationsList5
            onboardingSituationsList6
            onboardingSituationsList7
            onboardingSituationsList8
            onboardingSituationsList9
            onboardingSituationsDescription2
            onboardingDeployDescription
            onboardingDeployStep1Title
            onboardingDeployStep1Item1
            onboardingDeployStep1Item2
            onboardingDeployStep1Item3
            onboardingDeployStep1Item4
            onboardingDeployStep1Item5
            onboardingDeployStep1Item6
            onboardingDeployStep2Title
            onboardingDeployStep2Description
            onboardingDeployStep3Title
            onboardingDeployStep3Description
            onboardingFaq1Question
            onboardingFaq1Answer1
            onboardingFaq1Answer2
            onboardingFaq1AnswerList1
            onboardingFaq1AnswerList2
            onboardingFaq1AnswerList3
            onboardingFaq1AnswerList4
            onboardingFaq1AnswerList5
            onboardingFaq2Question
            onboardingFaq2Answer1
            onboardingFaq2Answer2
            onboardingFaq3Question
            onboardingFaq3Answer1
            onboardingFaq3Answer2
            onboardingFaq4Question
            onboardingFaq4Answer1
            onboardingFaq4Answer2
            onboardingFaq4Answer3
            schoolOnboardingHeroTitle
            schoolOnboardingHeroDescription
            schoolOnboardingVideoDescription1
            schoolOnboardingVideoDescription2
            schoolOnboardingVideoTitle
            schoolOnboardingSection1Title
            schoolOnboardingSection2Title
            schoolOnboardingSection3Title
            schoolOnboardingSection4Title
            schoolOnboardingSection5Title
            schoolOnboardingFeature1Title
            schoolOnboardingFeature1Description
            schoolOnboardingFeature2Title
            schoolOnboardingFeature2Description
            schoolOnboardingFeature3Title
            schoolOnboardingFeature3Description
            schoolOnboardingFeature4Title
            schoolOnboardingFeature4Description
            schoolOnboardingSection2Title
            schoolOnboardingSituationsDescription1
            schoolOnboardingSituationsList1
            schoolOnboardingSituationsList2
            schoolOnboardingSituationsList3
            schoolOnboardingSituationsList4
            schoolOnboardingSituationsList5
            schoolOnboardingSituationsList6
            schoolOnboardingSituationsList7
            schoolOnboardingSituationsList8
            schoolOnboardingSituationsList9
            schoolOnboardingSituationsDescription2
            schoolOnboardingHowDoWeHelpDescription
            schoolOnboardingHelpListItem1
            schoolOnboardingHelpListItem2
            schoolOnboardingHelpListItem3
            schoolOnboardingHelpListItem4
            schoolOnboardingHelpListItem5
            schoolOnboardingHelpListItem6
            schoolTrainingThemesTitle
            schoolTrainingThemesList
            schoolsTrainingDescriptionTitle
            schoolsTrainingInfo1Highlights
            schoolsTrainingDescriptionText1
            schoolsTrainingDescriptionText2
            schoolsTrainingDescriptionTextBold
            schoolsTrainingDescriptionText4
            schoolOnboardingHelpQuote1
            schoolOnboardingHelpQuote2
            schoolOnboardingFaq1Question
            schoolOnboardingFaq1Answer
            schoolOnboardingFaq2Question
            schoolOnboardingFaq2Answer1
            schoolOnboardingFaq2Answer2
            schoolOnboardingFaq2AnswerLinkUrl
            schoolOnboardingFaq2AnswerLinkText
            schoolOnboardingFaq3Question
            schoolOnboardingFaq3Answer
            schoolOnboardingFaq4Question
            schoolOnboardingFaq4Answer1
            schoolOnboardingFaq4Answer2
            schoolOnboardingFaq4Answer3
            schoolOnboardingFaq4Answer4
            schoolOnboardingFaq4Answer3LinkUrl
            schoolOnboardingFaq4Answer3LinkText
            schoolOnboardingFaq5Question
            schoolOnboardingFaq5Answer
            onboardingFaq4Answer3Part1
            onboardingFaq4Answer3Part2
            onboardingFaq4Answer3LinkText
            report2022DownloadTitle
            report2022DownloadDescription
            report2022DownloadDescriptionShort
            report2023DownloadTitle
            report2023DownloadDescription
            report2023DownloadDescriptionShort
            report2024DownloadTitle
            report2024DownloadDescription
            report2024DownloadDescriptionShort
            reportsDownloadTitle
            reportsDownloadDescription
            reportDownloadButton
            reportsDownloadButton
            formFieldEntityTitle
            allLanguages
            specialWebinarTitle
            specialWebinarDescription
            specialWebinarBadge1
            specialWebinarBadge2
            aboutUsStats1Text1
            aboutUsStats1Text2
            aboutUsStats1Text3
            aboutUsStats2Text1
            aboutUsStats2Text2
            aboutUsStats2Text3
            aboutUsTimeline1
            aboutUsTimeline2
            aboutUsTimeline3
            aboutUsTimeline4
            aboutUsVideoTitle
            aboutUsVideo1Link
            aboutUsVideo2Link
            aboutUsVideoContent1
            aboutUsVideoContent2
            aboutUsStoryTitle
            aboutUsStoryContent1
            aboutUsStoryContent2
            aboutUsCommunitiesTitle
            aboutUsCommunitiesContent
            aboutUsVisionTitle
            aboutUsVisionContent1
            aboutUsVisionContent2
            aboutUsVisionContent3
            aboutUsSecurityTitle
            aboutUsSecurityContent1
            aboutUsSecurityContent2
            aboutUsTeamTitle
            aboutUsTeamSubtitle
            aboutUsTeamContent1
            aboutUsTeamLink1
            aboutUsTeamLink2
          }
        }
      }
    }
  }
`

const useTranslations = (forcedLocale = null) => {
  // Grab the locale (passed through context) from the Context Provider
  const { locale } = useContext(LocaleContext)
  // Query the JSON files in <rootDir>/i18n/translations
  const { rawData } = useStaticQuery(query)

  const experimentContext = useContext(ExperimentContext)

  const experiment = experimentContext
    ? experimentContext.experiment
    : "default"

  // Simplify the response from GraphQL
  const simplified = rawData.edges.map((item) => {
    return {
      name: item.node.name,
      translations: item.node.translations,
    }
  })

  const defaultFile = simplified.filter(
    (lang) => lang.name === `${forcedLocale || locale}-default`,
  )[0]

  const experimentFile = simplified.filter(
    (lang) => lang.name === `${forcedLocale || locale}-${experiment}`,
  )[0]
  const { translations } = experimentFile || defaultFile

  return translations
}

export default useTranslations
